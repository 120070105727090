import React, { useState, useEffect } from 'react'
import Slider from 'react-slick'

import Layout from "../components/layout"
import SEO from "../components/seo"

import 'slick-carousel/slick/slick.css'

import * as style from './style/project.module.scss'

import GridLayout, { GridItem } from '../components/utils/GridLayout'

/**
 * Mobile breakpoint
 * @type {Number}
 */
const BREAKPOINT = 768;
/**
 * Slider basic options
 * @type {Object}
 */
const basicOptions = {
	arrows: false,
	variableWidth: true,
	centerMode: true
};

const sliderHandler = React.createRef();
const selectedImg = React.createRef();

/**
 * Go to slide n
 * @param  {Integer} n Slide position
 * @return {[type]}   [description]
 */
function goTo(n) {
	return(evt) => {
		if (evt.target.tagName !== 'A') {
			evt.preventDefault();

			if ( !sliderHandler.current ) return;
			let { slickGoTo } = sliderHandler.current;

			slickGoTo(n);

			if (window.innerWidth <= BREAKPOINT) {
				window.scroll({
					top: 0,
					behavior: 'smooth'
				});
			}
		}
	}
}

/**
 * Updat option on window resize
 * @param  {Object} options.options    		Current slider options
 * @param  {function} options.setOptions 	Option state modifier
 */
function resizeWindow({options, setOptions}) {
	return() => {
		// let nextOptions = Object.assign({}, options);
		if (window.innerWidth > BREAKPOINT && options.centerMode )
			return setOptions({...options, centerMode: false});

		if (window.innerWidth <= BREAKPOINT && !options.centerMode )
			return setOptions({...options, centerMode: true});
	}
}

export default function({data, pageContext, location}) {
	const [options, setOptions] = useState(basicOptions);
	const [selected, selectImg] = useState(null);

	useEffect(() => {
		let handleResize = resizeWindow({options, setOptions});

		handleResize();

		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		}
	}, [options]);

	const alt = `${pageContext.author} for ${pageContext.client}, makeup by Saraï Fiszel`;
	const description = `Photos & videos by ${pageContext.author} for ${pageContext.client}, makeup by Saraï Fiszel.`
	
	let images = pageContext.images ? pageContext.images : [];
	let { video } = pageContext;

	return (
		<Layout>
			<SEO 
				title = { `${pageContext.author} x ${pageContext.client}` } 
				description = { description }/>
			<article 
				id = 'Project' 
				className = { style.project }>

					{/* Fullscreen img */}
					{selected ? 
						<div 
							id = { style.Modal }
							onClick = { (evt) => {
								if (selectedImg.current.contains(evt.target))
									return;
								selectImg(null);
							} }>
							<a
								role = 'button'
								onClick = { (evt) => {
									evt.preventDefault();
									selectImg(null);
								}}
								className = { style.modalClose }>+</a>
							<img 
								ref = { selectedImg }
								src = { selected.publicURL } 
								alt = { alt } />
						</div> 
					: null }
				

				<div className = 'wrapper'>

					<div 
						itemScope
						itemType = 'http://schema.org/ItemList'
						className = { style.sliderWrapper }>
						<meta 
							itemProp = "name"
							content = { `Photos taken by ${pageContext.author} for ${pageContext.client}` } />
						{ images.length > 1 ? 
						<Slider {...options} ref = { sliderHandler } >
							{ images.map(({publicURL, id, mobileImage, desktopImage}, i) => (
								<div key = { i }>
									<div 
										itemProp = 'itemListElement'
										itemScope
										itemType = 'http://schema.org/ImageObject'
										className = { style.projectImg } 
										onClick = { goTo(i) }>
										<meta 
											itemProp = 'description'
											content = { alt } />
										<meta 
											itemProp = 'author'
											content = { pageContext.author } />

										<img
										  itemProp = 'contentUrl'
										  src = { publicURL }
										  alt = { alt } />

										{ video ? 
											<a
												target = '_blank'
												href = { video }
												className = { style.projectImg__open} >
												+
											</a> :
											<button
												onClick = { evt => selectImg({publicURL}) }
												className = { style.projectImg__open} >
												+
											</button>
										}
									</div>
								</div>
							)) }
						</Slider> : 
						<div>
							<div 
								itemProp = 'itemListElement'
								itemScope
								itemType = 'http://schema.org/ImageObject'
								className = { style.projectImg } >
								<meta 
									itemProp = 'description'
									content = { alt } />
								<meta 
									itemProp = 'author'
									content = { pageContext.author } />
								<div className = { style.projectImg__single} >
									<img
									  itemProp = 'contentUrl'
									  src = { images[0].publicURL }
									  alt = { alt } />
								</div>
								{ video ? 
									<a
										target = '_blank'
										href = { video }
										className = { style.projectImg__open} >
										+
									</a> :
									<button
										onClick = { evt => selectImg({
											publicURL: images[0].publicURL
										}) }
										className = { style.projectImg__open} >
										+
									</button>
								}
							</div>
						</div> }
					</div>

					<div
						itemScope
						itemType = 'http://schema.org/ItemList' 
						className = { style.projectInfo }>
					
						<meta 
							itemProp = "name"
							content = { `Photos taken by ${pageContext.author} for ${pageContext.client}` } />
						<h1>{ pageContext.client }</h1>
						<p>{ pageContext.author }</p>

						<GridLayout className = 'grid-small'>
							<meta 
								itemProp = "name"
								content = { `Photos taken by ${pageContext.author} for ${pageContext.client}` } />
							{ images.map(({publicURL, id, mobileImage, desktopImage}, index) => (
								<GridItem 
									key = { index } 
									grid = {{ col: {xs: 4, sm: 4, md: 4, lg: 4 }}}>
									<div 
										itemProp = 'itemListElement'
										itemScope
										itemType = 'http://schema.org/ImageObject'
										className = { `img-sqr ${style.projectThumb}` }
										onClick = { goTo(index) }>
										<meta 
											itemProp = 'description'
											content = { alt } />
										<meta 
											itemProp = 'author'
											content = { pageContext.author } />
										<img
										  itemProp = 'contentUrl'
										  src = { publicURL }
										  alt = { alt } />
									</div>
								</GridItem>
							)) }
						</GridLayout>
					</div>

				</div>
			</article>
		</Layout>
	)
}